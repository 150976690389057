import { getLocationLabel, hexToEmo } from 'hc-core/composables/misc.js'
import { isoToMask } from 'hc-core/composables/time.js'
import { useEmailValidator, usePhoneValidator, useUrlValidator } from 'hc-core/mixins/validation'

export default {
  data () {
    return {
      entity: null,
      showEndStepProp: false,
    }
  },
  computed: {
    steps () {
      return [
        {
          icon: 'uil:chat-info',
          slug: 'general',
          focusId: 'general',
          label: 'profile.form.general',
          component: 'MultipleItems',
          multipleItemsData: [
            {
              icon: 'uil:user',
              field: 'name',
              component: 'TextInput',
              label: 'profile.name',
            },
            {
              icon: 'uil:envelope-alt',
              field: 'metadata._resume.email',
              component: 'TextInput',
              label: 'profile.email',
              type: 'email',
              rules: [useEmailValidator]
            },
            {
              icon: 'uil:phone-alt',
              field: 'metadata._resume.phone',
              component: 'PhoneInput',
              label: 'profile.phone',
            },
            {
              icon: 'uil:map-marker',
              field: 'locations',
              slug: 'locations',
              label: 'profile.address',
              labelAdd: 'profile.form.address.add',
              component: 'LocationsInput',
              buildFn: (i) => { return i ? getLocationLabel(i) : null },
            },
            {
              icon: 'uil:linkedin',
              field: 'metadata._resume.links.linkedin',
              optional: true,
              component: 'TextInput',
              rules: [useUrlValidator],
              label: 'profile.linkedin',
              isLink: true
            },
            {
              icon: 'uil:calender',
              field: 'metadata._resume.birthdate',
              component: 'ISOInput',
              mask: 'DD/MM/YYYY',
              light: true,
              pickDate: true,
              label: 'profile.birthDate',
            },
          ],
        },
        {
          icon: 'uil:pen',
          slug: 'search',
          focusId: 'search',
          label: 'profile.form.search.label',
          field: 'customAttributes.jobReferential',
          fields: ['customAttributes.jobReferential', 'customAttributes.skillsReferential', 'metadata._resume.preferredJob', 'customAttributes.verifiedProfile'],
          component: 'ProfileJobSkills',
        },
        {
          icon: 'uil:user-exclamation',
          slug: 'summary',
          focusId: 'summary',
          label: 'profile.description.label',
          component: 'MultipleItems',
          multipleItemsData: [
            {
              icon: 'uil:file-alt',
              field: 'description',
              label: 'profile.description.label',
              hint: 'profile.description.hint',
              component: 'TextInput',
              optional: true,
              maxlength: 800,
              class: 'col-12 q-mb-md',
              type: 'textarea',
            },
            {
              icon: 'uil:flask',
              field: 'customAttributes.experienceNb',
              component: 'TextInput',
              type: 'number',
              label: 'profile.experience.label',
              hint: 'profile.experience.hint',
              optional: true,
            },
            {
              icon: 'uil:history',
              field: 'customAttributes.accountantBackground',
              component: 'OptionsInput',
              type: 'single',
              label: 'profile.accountantBackground.label',
              hint: 'profile.accountantBackground.hint',
              optional: true,
              options: [
                { value: true, label: 'profile.accountantBackground.active', icon: 'uil:check' },
                { value: false, label: 'profile.accountantBackground.disabled', icon: 'uil:times', default: true },
              ]
            },
          ],
          optional: true,
        },
        {
          icon: 'uil:briefcase',
          field: 'metadata._resume.experiences',
          type: 'array',
          slug: 'experiences',
          focusId: 'experiences',
          component: 'ItemsArrayInput',
          arrayItemModel: [
            { field: 'title', component: 'TextInput', label: 'profile.experienceTitle', counter: true, maxlength: 120, isTitle: true, class: 'col-12 q-mb-xs' },
            { field: 'org', component: 'TextInput', label: 'profile.experienceOrg', counter: true, maxlength: 120, isSubtitle: true, class: 'col-12 q-mb-xs' },
            { field: 'location', component: 'PlacesAutocomplete', label: 'profile.experienceLocation', optional: true, class: 'col-12 q-mb-md', style: 'max-width:100%' },
            { field: 'startDate', component: 'ISOInput', label: 'profile.experienceStartDate', mask: 'MM/YYYY', pickDate: true, optional: true, class: 'col-6' },
            { field: 'endDate', component: 'ISOInput', label: 'profile.experienceEndDate', mask: 'MM/YYYY', pickDate: true, optional: true, class: 'col-6' },
            { field: 'description', component: 'TextInput', label: 'profile.experienceDescription', maxlength: 800, optional: true, class: 'col-12', type: 'textarea', hint: 'profile.form.experiences.missions' },
          ],
          addLabel: 'profile.form.experiences.add',
          label: 'profile.experiences',
          hint: 'profile.form.experiences.hint',
          buildSnippet: 'list',
          buildFn: (i) => {
            return this.$t({ id: 'profile.experienceBuildFn' }, {
              title: this.$_.get(i, 'title', undefined),
              org: this.$_.get(i, 'org', undefined),
              date: `${isoToMask(this.$_.get(i, 'startDate', undefined), 'MM/YYYY')} - ${isoToMask(this.$_.get(i, 'endDate', undefined), 'MM/YYYY')}`,
            })
          },
          buildComponent: 'ItemsArray'
        },
        {
          icon: 'uil:graduation-cap',
          field: 'metadata._resume.education',
          type: 'array',
          slug: 'education',
          focusId: 'education',
          component: 'ItemsArrayInput',
          arrayItemModel: [
            { field: 'title', component: 'TextInput', label: 'profile.educationTitle', counter: true, maxlength: 120, isTitle: true, class: 'col-12 q-mb-xs' },
            { field: 'org', component: 'TextInput', label: 'profile.educationOrg', counter: true, maxlength: 120, isSubtitle: true, class: 'col-12 q-mb-xs' },
            { field: 'location', component: 'PlacesAutocomplete', label: 'profile.educationLocation', optional: true, class: 'col-12 q-mb-md', style: 'max-width:100%' },
            { field: 'startDate', component: 'ISOInput', label: 'profile.educationStartDate', mask: 'MM/YYYY', pickDate: true, optional: true, class: 'col-6' },
            { field: 'endDate', component: 'ISOInput', label: 'profile.educationEndDate', mask: 'MM/YYYY', pickDate: true, optional: true, class: 'col-6' },
            { field: 'mention', component: 'TextInput', label: 'profile.educationMention', optional: true, class: 'col-12' },
          ],
          addLabel: 'profile.form.education.add',
          label: 'profile.education',
          hint: 'profile.form.education.hint',
          buildSnippet: 'list',
          buildFn: (i) => {
            const st = this.$_.get(i, 'startDate', undefined)
            const en = this.$_.get(i, 'endDate', undefined)
            return this.$t({ id: 'profile.educationBuildFn' }, {
              title: this.$_.get(i, 'title', undefined),
              org: this.$_.get(i, 'org', undefined),
              date: (st || en) ? `${st ? isoToMask(st, 'MM/YYYY') : '/'} - ${en ? isoToMask(en, 'MM/YYYY') : '/'}` : undefined,
              mention: this.$_.get(i, 'mention', undefined),
            })
          },
          buildComponent: 'ItemsArray'
        },
        {
          icon: 'uil:medal',
          field: 'metadata._resume.skills',
          type: 'array',
          slug: 'skills',
          focusId: 'skills',
          component: 'ItemsArrayInput',
          arrayItemModel: [
            { field: 'title', component: 'TextInput', label: 'profile.skillTitle', counter: true, maxlength: 120, isTitle: true, class: 'col-8 q-mb-xs' },
            { field: 'type', component: 'SelectInput', label: 'profile.skillType', optional: true, isSubtitle: true, class: 'col-4', options: [{ label: 'Soft-Skill', value: 'soft_skill' }, { label: 'Hard-Skill', value: 'hard_skill' }] },
            // { field: 'emoji', component: 'EmojiInput', counter: true, maxlength: 30, class: 'col-2' },
            { field: 'description', component: 'TextInput', label: 'profile.skillDescription', maxlength: 100, optional: true, class: 'col-12 q-mt-xs' },
          ],
          optional: true,
          addLabel: 'profile.form.skills.add',
          label: 'profile.skills',
          buildSnippet: 'list',
          buildFn: (i) => { return `${i.emoji ? hexToEmo(i.emoji) : ''} ${this.$t({ id: 'profile.skillBuildFn' }, { title: this.$_.get(i, 'title', undefined), description: this.$_.get(i, 'description', undefined) })}` },
          isSubtitleFn: (i) => { return this.$_.get([{ label: 'Soft-Skill', value: 'soft_skill' }, { label: 'Hard-Skill', value: 'hard_skill' }].find(o => o.value === i.type), 'label', 'Type à compléter') },
          buildComponent: 'ItemsArray'
        },
        {
          icon: 'uil:wrench',
          field: 'customAttributes.tools',
          fields: ['customAttributes.tools', 'metadata._resume.tools_custom'],
          slug: 'tools',
          label: 'profile.tools',
          component: 'ToolsPicker',
          optional: true,
          clearable: true,
          buildSnippet: 'tools',
          buildFn: (i) => {
            const r = this.$_.concat(this.$_.map(this.$_.get(i, 'tools', []), t => this.$t({ id: `customAttributes.tools.${t}` })), this.$_.get(i, 'tools_custom', []))
            return this.$_.compact(r).length ? this.$_.compact(r).map((t) => t.replaceAll('_', ' ')) : null
          },
          buildComponent: 'Tools'
        },
        {
          icon: 'uil:language',
          field: 'metadata._resume.languages',
          type: 'array',
          slug: 'languages',
          focusId: 'languages',
          component: 'ItemsArrayInput',
          arrayItemModel: [
            { field: 'emoji', component: 'EmojiInput', counter: true, maxlength: 30, class: 'col-2' },
            { field: 'title', component: 'TextInput', label: 'Label', counter: true, maxlength: 30, isTitle: true, class: 'col-6 q-mb-xs' },
            {
              field: 'level',
              label: 'profile.languageLevel',
              component: 'SelectInput',
              class: 'col-12',
              options: [
                { label: this.$t({ id: 'profile.languageLevels.A' }), value: 'A' },
                { label: this.$t({ id: 'profile.languageLevels.A1' }), value: 'A1' },
                { label: this.$t({ id: 'profile.languageLevels.A2' }), value: 'A2' },
                { label: this.$t({ id: 'profile.languageLevels.B' }), value: 'B' },
                { label: this.$t({ id: 'profile.languageLevels.B1' }), value: 'B1' },
                { label: this.$t({ id: 'profile.languageLevels.B2' }), value: 'B2' },
                { label: this.$t({ id: 'profile.languageLevels.C' }), value: 'C' },
                { label: this.$t({ id: 'profile.languageLevels.C1' }), value: 'C1' },
                { label: this.$t({ id: 'profile.languageLevels.C2' }), value: 'C2' },
              ],
            },
          ],
          optional: true,
          addLabel: 'profile.form.languages.add',
          label: 'profile.languages',
          buildSnippet: 'list',
          buildFn: (i) => { return `${i.emoji ? hexToEmo(i.emoji) : ''} ${this.$t({ id: 'profile.languageBuildFn' }, { title: this.$_.get(i, 'title', undefined), level: this.$_.get(i, 'level', undefined) })}` },
          isTitleFn: (i) => { return `${i.emoji ? hexToEmo(i.emoji) : ''} ${this.$t({ id: 'profile.languageBuildFn' }, { title: this.$_.get(i, 'title', undefined), level: this.$_.get(i, 'level', undefined) })}` },
          buildComponent: 'ItemsArray'
        },
        {
          icon: 'uil:camera',
          field: 'metadata._files.avatar',
          slug: 'avatar',
          label: 'profile.avatar',
          component: 'AvatarInput',
          optional: true,
          getAll: false,
          useEntityId: true,
          uploadFolder: 'images/avatars',
          prefix: 'avatar',
          // ImgageCropperUploader
          isCircle: true,
          size: { width: '10rem', height: '10rem' },
          hint: 'Cliquez ou glissez votre fichier ici',
          key: 'metadata._files.avatar', // Consider dump it
        },
        {
          icon: 'uil:basketball',
          field: 'metadata._resume.hobbies',
          type: 'array',
          slug: 'hobbies',
          focusId: 'hobbies',
          component: 'ItemsArrayInput',
          arrayItemModel: [
            { field: 'title', component: 'TextInput', label: 'profile.hobbyTitle', counter: true, maxlength: 120, isTitle: true, class: 'col-6 q-mb-xs' },
            { field: 'emoji', component: 'EmojiInput', counter: true, maxlength: 30, class: 'col-2' },
            { field: 'description', component: 'TextInput', label: 'profile.hobbyDescription', maxlength: 100, optional: true, class: 'col-12 q-mt-xs' },
          ],
          optional: true,
          addLabel: 'profile.form.hobbies.add',
          label: 'profile.hobbies',
          buildSnippet: 'list',
          buildFn: (i) => { return `${i.emoji ? hexToEmo(i.emoji) : ''} ${this.$t({ id: 'profile.hobbyBuildFn' }, { title: this.$_.get(i, 'title', undefined), description: this.$_.get(i, 'description', undefined) })}` },
          buildComponent: 'ItemsArray'
        },
        {
          icon: 'uil:link',
          field: 'metadata._resume.referees',
          type: 'array',
          slug: 'referees',
          focusId: 'referees',
          component: 'ItemsArrayInput',
          arrayItemModel: [
            { field: 'name', component: 'TextInput', label: 'profile.refereeName', counter: true, maxlength: 120, isTitle: true, class: 'col-12 q-mb-xs' },
            { field: 'org', component: 'TextInput', label: 'profile.refereeOrg', counter: true, maxlength: 120, isSubtitle: true, class: 'col-12 q-mb-xs' },
            { field: 'email', component: 'TextInput', label: 'profile.refereeEmail', optional: true, class: 'col-6', type: 'email', rules: [useEmailValidator] },
            { field: 'phone', component: 'TextInput', label: 'profile.refereePhone', optional: true, mask: '## ## ## ## ##', rules: [usePhoneValidator], class: 'col-6' },
          ],
          optional: true,
          addLabel: 'component.forms.profileForm.referees.add',
          label: 'profile.referees',
          buildSnippet: 'list',
          buildFn: (i) => { return this.$t({ id: 'profile.refereeBuildFn' }, { name: this.$_.get(i, 'name', undefined), org: this.$_.get(i, 'org', undefined) }) },
          buildComponent: 'ItemsArray'
        },
        {
          icon: 'uil:globe',
          slug: 'settings',
          label: 'profile.form.settings',
          component: 'MultipleItems',
          getAll: false,
          multipleItemsData: [
            {
              field: 'customAttributes.library',
              component: 'OptionsInput',
              type: 'single',
              label: 'profile.library.label',
              optional: true,
              isCgu: true,
              options: [
                { value: true, label: 'profile.library.active', icon: 'uil:check', default: true },
                { value: false, label: 'profile.library.disabled', icon: 'uil:times' },
              ]
            },
            {
              field: 'customAttributes.publicProfile',
              component: 'OptionsInput',
              type: 'single',
              label: 'profile.publicProfile.label',
              hint: 'profile.publicProfile.hint',
              optional: true,
              options: [
                { value: true, label: 'profile.publicProfile.active', icon: 'uil:check', default: true },
                { value: false, label: 'profile.publicProfile.disabled', icon: 'uil:times' },
              ]
            },
            {
              field: 'customAttributes.searchStatus',
              label: 'profile.searchStatus.label',
              hint: 'profile.searchStatus.hint',
              component: 'OptionsInput',
              optional: true,
              class: 'col-12',

              options: [
                { value: true, label: 'profile.searchStatus.true', icon: 'uil:check', default: true },
                { value: false, label: 'profile.searchStatus.false', icon: 'uil:times' },
              ],
            },
          ],
          buildSnippet: 'multiple',
        },
      ].filter((step) => this.$_.get(step, 'if', true)).map((step, i) => { return this.$_.set(step, 'index', i) })
    },

    formStructure () {
      return {
        endStep: {
          title: 'profile.form.endTitle',
          subtitle: 'profile.form.endSubtitle',
          image: 'thumbsUpMan',
          buttons: [
            {
              color: 'primary',
              label: 'layouts.drawer.profile',
              route: { name: 'profile' }
            }
          ]
        },
        defaultFields: [
          { path: 'customAttributes.library', value: true },
          { path: 'customAttributes.publicProfile', value: true },
          { path: 'customAttributes.searchStatus', value: 'look' },
        ],
        preview: {
          component: 'ApplicantBuild',
          quitButton: this.$t({ id: 'pages.offer_edit.quitButton' }),
        },
        returnRoute: 'profile'
      }
    },
  },
  async mounted () {
    await this.refresh()
  },
  methods: {
    async refresh () {
      if (process.env.HC_PLATFORM === 'APP') {
        this.entity = await this.$store.dispatch('asset/read', {
          assetId: this.$_.get(this, 'currentUser.metadata._resume.profileAssetId', this.$_.get(this, 'currentNaturalUser.metadata._resume.profileAssetId', this.$_.get(this.user, 'id', this.$route.params.slug)))
        })
      } else if (process.env.HC_PLATFORM === 'WEB' && this.$route.params.slug) {
        this.entity = await this.$store.dispatch('asset/getBySlug', {
          slug: this.$route.params.slug
        })
      } else if (process.env.HC_PLATFORM === 'MANAGER' && this.$route.params.id) {
        // Dirty way of grabbing back entity value that overlaps
        this.entity = await this.$store.dispatch('asset/read', {
          id: this.$route.params.id
        })
      }
    },
    // Form events
    async formEvent (evt) {
      try {
        if (evt.method) {
          switch (evt.method) {
            case 'saveStep': {
              const assetId = this.$_.get(this.entity, 'id', this.$_.get(evt, 'entityId', null))
              if (assetId) {
                this.entity = await this.$store.dispatch('asset/update', {
                  assetId,
                  attrs: Object.assign(evt.value, { customAttributes: { lastActivity: new Date().toISOString() } })
                })
              }
              break
            }
            case 'callback':
              await this.formCallback()
              break
            case 'setShowProps':
              this.$_.set(this, 'showEndStepProp', evt.value)
              break
            case 'refresh':
              await this.refresh()
              break
          }
        }
      } catch (e) { this.useLogger(e) }
    },
    async formCallback () {
      try {
        // Setting up some properties
        this.showEndStepProp = true
      } catch (e) { this.useLogger(e) }
    },
  },
}
